//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";
import ArticleCard from "~/components/blog/ArticleCard.vue";

export default BaseCmsBlock.extend({
  name: "LastArticlesBlock",

  data: function () {
    return {
      articles: this.extras?.articles || [],
    };
  },

  components: {
    ArticleCard
  }
});
